/**
 * Uchi-ui is fully deployed in FedRamp ONLY.
 * In the commercial environment, Start redirects users to Atlas (home.atlassian.com (or whatever is next ;) ))
 */

import { getEnv } from './config';
import { redirect } from './facade/window';
import { configureSentry } from './sentry';
import { getSessionSynchronisationUrl } from './util/auth';

configureSentry();

const HOME_REDIRECT_URL = getEnv() === 'stg-east' ? 'https://home.stg.atlassian.com' : 'https://home.atlassian.com';

const redirectToAtlas = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const urlWithSynchronisation = getSessionSynchronisationUrl(
    `${HOME_REDIRECT_URL}?${searchParams.toString()}`,
    undefined,
    'townsquare',
  );

  redirect(urlWithSynchronisation);
};

redirectToAtlas();
